<template>
    <div>
        <el-table
            :data="workingDayTableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column
                v-if="mode === 1"
                prop="working_day"
                label="工作日"
            ></el-table-column>
            <el-table-column v-if="mode === 2" prop="working_day" label="日期">
                <template v-slot:default="scope">
                    <el-date-picker
                        class="sp_date_picker"
                        v-model="workingDayTableData[scope.$index].working_day"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                    >
                    </el-date-picker>
                </template>
            </el-table-column>

            <el-table-column label="当天工作">
                <template v-slot:default="scope">
                    <el-select
                        v-model="workingDayTableData[scope.$index].is_work"
                        @change="onTypeChange(scope.$index)"
                    >
                        <el-option
                            v-for="item in workOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="scope.$index == 0 && item.value == null"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="打卡次数">
                <template v-slot:default="scope">
                    <div v-if="rest_index.includes(scope.$index)">-</div>
                    <el-select
                        v-else
                        v-model="workingDayTableData[scope.$index].punch_times"
                        @change="onTypeChange()"
                    >
                        <el-option
                            v-for="item in punchTimesOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled="scope.$index == 0 && item.value == null"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!-- 上班时间1 -->
            <el-table-column label="上班时间">
                <template v-slot:default="scope">
                    <div v-if="rest_index.includes(scope.$index)">-</div>
                    <el-time-picker
                        v-else-if="
                            (scope.$index - 1 >= 0 &&
                                rest_index.includes(scope.$index - 1)) ||
                            workingDayTableData[scope.$index].start_time
                        "
                        value-format="HH:mm"
                        format="HH:mm"
                        arrow-control
                        :clearable="
                            scope.$index - 1 >= 0 &&
                            !rest_index.includes(scope.$index - 1)
                        "
                        v-model="workingDayTableData[scope.$index].start_time"
                        @change="timeCheck(true)"
                    >
                    </el-time-picker>
                    <el-select
                        v-else
                        v-model="workingDayTableData[scope.$index].start_time"
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!-- 下班时间1 -->
            <el-table-column label="下班时间">
                <template v-slot:default="scope">
                    <div
                        v-if="
                            rest_index.includes(scope.$index) ||
                            (mode === 1 &&
                                twice_index.includes(scope.$index)) ||
                            (mode === 2 &&
                                workingDayTableData[scope.$index]
                                    .punch_times === 2)
                        "
                    >
                        -
                    </div>
                    <el-time-picker
                        v-else-if="
                            (scope.$index - 1 >= 0 &&
                                rest_index.includes(scope.$index - 1)) ||
                            workingDayTableData[scope.$index].sub_end_time
                        "
                        value-format="HH:mm"
                        format="HH:mm"
                        arrow-control
                        :clearable="
                            scope.$index - 1 >= 0 &&
                            !rest_index.includes(scope.$index - 1)
                        "
                        v-model="workingDayTableData[scope.$index].sub_end_time"
                        @change="timeCheck(true)"
                    >
                    </el-time-picker>
                    <el-select
                        v-else
                        v-model="workingDayTableData[scope.$index].sub_end_time"
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!-- 上班时间2 -->
            <el-table-column label="上班时间">
                <template v-slot:default="scope">
                    <div
                        v-if="
                            rest_index.includes(scope.$index) ||
                            (mode === 1 &&
                                twice_index.includes(scope.$index)) ||
                            (mode === 2 &&
                                workingDayTableData[scope.$index]
                                    .punch_times === 2)
                        "
                    >
                        -
                    </div>
                    <el-time-picker
                        v-else-if="
                            (scope.$index - 1 >= 0 &&
                                rest_index.includes(scope.$index - 1)) ||
                            workingDayTableData[scope.$index].sub_start_time
                        "
                        value-format="HH:mm"
                        format="HH:mm"
                        arrow-control
                        :clearable="
                            scope.$index - 1 >= 0 &&
                            !rest_index.includes(scope.$index - 1)
                        "
                        v-model="
                            workingDayTableData[scope.$index].sub_start_time
                        "
                        @change="timeCheck(true)"
                    >
                    </el-time-picker>
                    <el-select
                        v-else
                        v-model="
                            workingDayTableData[scope.$index].sub_start_time
                        "
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <!-- 下班时间2 -->
            <el-table-column label="下班时间">
                <template v-slot:default="scope">
                    <div v-if="rest_index.includes(scope.$index)">-</div>
                    <el-time-picker
                        v-else-if="
                            (scope.$index - 1 >= 0 &&
                                rest_index.includes(scope.$index - 1)) ||
                            workingDayTableData[scope.$index].end_time
                        "
                        value-format="HH:mm"
                        format="HH:mm"
                        arrow-control
                        :clearable="
                            scope.$index - 1 >= 0 &&
                            !rest_index.includes(scope.$index - 1)
                        "
                        v-model="workingDayTableData[scope.$index].end_time"
                        @change="timeCheck(true)"
                    >
                    </el-time-picker>
                    <el-select
                        v-else
                        v-model="workingDayTableData[scope.$index].end_time"
                    >
                        <el-option
                            v-for="item in timeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column v-if="mode === 2" label="操作">
                <template v-slot:default="scope">
                    <div class="operations">
                        <div
                            style="color: #4db9d5;"
                            @click="deleteSpecialDate(scope.$index)"
                        >
                            删除
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div
            class="custom_button plain no_select operation_button"
            @click="addSpecialDate"
            v-if="mode === 2"
        >
            添加
        </div>
    </div>
</template>

<script>
import { backDate } from "@/util/utils.js";

export default {
    data() {
        return {
            rest_index: [5, 6],
            twice_index: [0, 1, 2, 3, 4, 5, 6],
            workingDayTableData: [],
            timeOptions: [
                {
                    value: null,
                    label: "同上",
                },
                {
                    value: "9:00",
                    label: "设置",
                },
            ],
            punchTimesOptions: [],
            workOptions: [],

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    props: {
        mode: {
            type: Number,
            required: true,
        },
        init: {
            type: Array,
        },
    },
    created() {
        console.log(this.mode);
        if (this.mode === 1) {
            this.workingDayTableData = [
                {
                    working_day: "星期一",
                    is_work: true,
                    punch_times: 2,
                    start_time: "09:00",
                    sub_end_time: "12:00",
                    sub_start_time: "14:00",
                    end_time: "18:00",
                },
                {
                    working_day: "星期二",
                    is_work: null,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
                {
                    working_day: "星期三",
                    is_work: null,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
                {
                    working_day: "星期四",
                    is_work: null,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
                {
                    working_day: "星期五",
                    is_work: null,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
                {
                    working_day: "星期六",
                    is_work: false,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
                {
                    working_day: "星期日",
                    is_work: null,
                    punch_times: null,
                    start_time: null,
                    sub_end_time: null,
                    sub_start_time: null,
                    end_time: null,
                },
            ];
            this.punchTimesOptions = [
                {
                    value: null,
                    label: "同上",
                },
                {
                    value: 2,
                    label: "2次",
                },
                {
                    value: 4,
                    label: "4次",
                },
            ];
            this.workOptions = [
                {
                    value: null,
                    label: "同上",
                },
                {
                    value: true,
                    label: "上班",
                },
                {
                    value: false,
                    label: "休息",
                },
            ];
        } else if (this.mode === 2) {
            this.workOptions = [
                {
                    value: true,
                    label: "上班",
                },
                {
                    value: false,
                    label: "休息",
                },
            ];
            this.punchTimesOptions = [
                {
                    value: 2,
                    label: "2次",
                },
                {
                    value: 4,
                    label: "4次",
                },
            ];
        }
        this.toEmit();
    },
    watch: {
        init(val) {
            if (this.mode === 1) {
                console.log("日程表", val);
                let inst = (i, item) => {
                    item.is_work = val[i].type == 1;
                    item.punch_times = val[i].mode ? Number(val[i].mode) : null;
                    item.start_time = val[i].punch1_time || null;
                    item.sub_end_time =
                        val[i].mode == 4 ? val[i].punch2_time || null : null;
                    item.sub_start_time = val[i].punch3_time || null;
                    item.end_time =
                        val[i].mode == 2
                            ? val[i].punch2_time || null
                            : val[i].punch4_time || null;
                    console.log(i, item);
                };
                inst(0, this.workingDayTableData[6]); //单独处理星期天
                for (let i = 0; i < this.workingDayTableData.length - 1; i++) {
                    const item = this.workingDayTableData[i];
                    inst(i + 1, item);
                }
                this.onTypeChange();
            } else if (this.mode === 2) {
                console.log("特殊日期", val);
                val.forEach((item, index) => {
                    this.$set(this.workingDayTableData, index, {
                        working_day: item.date,
                        is_work: item.type == 1,
                        punch_times: item.mode,
                        start_time: item.punch1_time || null,
                        sub_end_time:
                            item.mode == 4 ? item.punch2_time || null : null,
                        sub_start_time: item.punch3_time || null,
                        end_time:
                            item.mode == 2
                                ? item.punch2_time || null
                                : item.punch4_time || null,
                    });
                });
            }
        },
    },
    methods: {
        onTypeChange(index) {
            // 同上重置
            if (index) {
                console.log(index);
                let item = this.workingDayTableData[index];
                if (item.is_work === null) {
                    item.start_time = null;
                    item.sub_start_time = null;
                    item.sub_end_time = null;
                    item.end_time = null;
                    item.punch_times = null;
                }
            }

            // 休息日判定
            let rest_index = [];
            this.workingDayTableData.forEach((item, index) => {
                if (item.is_work === false) {
                    rest_index.push(index);
                } else if (
                    item.is_work === null &&
                    rest_index.includes(index - 1)
                ) {
                    // 同上休息日
                    rest_index.push(index);
                }
            });
            this.rest_index = rest_index;
            this.timeCheck();

            // 打卡次数判定
            let twice_index = [];
            this.workingDayTableData.forEach((item, index) => {
                if (item.is_work === false) {
                    twice_index.push(index);
                } else if (item.punch_times === 2) {
                    twice_index.push(index);
                } else if (
                    item.punch_times === null &&
                    twice_index.includes(index - 1)
                ) {
                    // 同上打卡
                    twice_index.push(index);
                }
            });
            this.twice_index = twice_index;
            this.timeCheck();

            this.toEmit();
        },

        timeCheck(isEmit) {
            // 检测到第一个打卡次数为空则赋予2
            for (let [index, item] of Object.entries(
                this.workingDayTableData
            )) {
                index = Number(index);
                if (
                    item.is_work &&
                    !item.punch_times &&
                    this.rest_index.includes(index - 1)
                ) {
                    item.punch_times =
                        this.workingDayTableData[index - 1].punch_times || 2;
                    break;
                }
            }
            // 检测到第一个上班时间1为空则赋予9:00
            for (let [index, item] of Object.entries(
                this.workingDayTableData
            )) {
                index = Number(index);
                if (
                    item.is_work &&
                    !item.start_time &&
                    this.rest_index.includes(index - 1)
                ) {
                    item.start_time = "9:00";
                    break;
                }
            }
            // 检测到第一个下班时间1为空则赋予12:00
            for (let [index, item] of Object.entries(
                this.workingDayTableData
            )) {
                index = Number(index);
                if (
                    item.punch_times === 4 &&
                    !item.sub_end_time &&
                    this.twice_index.includes(index - 1)
                ) {
                    item.sub_end_time = "12:00";
                    break;
                }
            }
            // 检测到第一个上班时间2为空则赋予14:00
            for (let [index, item] of Object.entries(
                this.workingDayTableData
            )) {
                index = Number(index);
                if (
                    item.punch_times === 4 &&
                    !item.sub_start_time &&
                    this.twice_index.includes(index - 1)
                ) {
                    item.sub_start_time = "14:00";
                    break;
                }
            }
            // 检测到第一个下班时间2为空则赋予18:00
            for (let [index, item] of Object.entries(
                this.workingDayTableData
            )) {
                index = Number(index);
                if (
                    item.is_work &&
                    !item.end_time &&
                    this.rest_index.includes(index - 1)
                ) {
                    item.end_time = "18:00";
                    break;
                }
            }

            if (isEmit) this.toEmit();
        },

        /**
         * 添加特殊日期
         */
        addSpecialDate() {
            this.workingDayTableData.push({
                working_day: backDate(new Date().getTime() / 1000),
                is_work: true,
                punch_times: 2,
                start_time: "9:00",
                sub_end_time: "12:00",
                sub_start_time: "14:00",
                end_time: "18:00",
            });
            this.toEmit();
        },

        /**
         * 删除特殊日期
         */
        deleteSpecialDate(index) {
            this.rest_index.splice(index, 1);
            this.workingDayTableData.splice(index, 1);
            this.toEmit();
        },

        toEmit() {
            // 发射数据
            if (this.mode === 1) {
                // 装填日期
                let work_time_data = {};
                let workingDayTableData = JSON.parse(
                    JSON.stringify(this.workingDayTableData)
                );
                for (let [index, item] of Object.entries(workingDayTableData)) {
                    index = Number(index);

                    let func = (field, isCheck) => {
                        if (item[field] === null) {
                            // 是否为同上
                            if (isCheck || item.is_work) {
                                // 是否硬检查 是否休息日
                                let t_index = index;
                                // console.log(item, index, field);
                                // 向上检查（建立在选择器稳定的基础上，第一项必有值，若找不到目标则会出现数据错误）
                                while (t_index > 0) {
                                    t_index--;
                                    // console.log(t_index, field);
                                    // 检测到非空则复制
                                    if (
                                        workingDayTableData[t_index][field] !==
                                        null
                                    ) {
                                        item[field] =
                                            workingDayTableData[t_index][field];
                                        break;
                                    }
                                }
                            }
                        }
                    };
                    func("is_work", true);
                    func("punch_times");
                    func("start_time");
                    func("sub_end_time");
                    func("sub_start_time");
                    func("end_time");

                    let obj = {
                        week: index + 1,
                        type: item.is_work ? 1 : 2,
                        mode: item.punch_times,
                    };
                    if (item.punch_times === 4) {
                        obj.punch1_time = item.start_time;
                        obj.punch2_time = item.sub_end_time;
                        obj.punch3_time = item.sub_start_time;
                        obj.punch4_time = item.end_time;
                    } else {
                        obj.punch1_time = item.start_time;
                        obj.punch2_time = item.end_time;
                    }

                    work_time_data[index + 1] = obj;
                }
                work_time_data[7].week = 0;
                work_time_data[0] = JSON.parse(
                    JSON.stringify(work_time_data[7])
                );
                delete work_time_data[7];
                this.$emit("change", work_time_data);
            } else if (this.mode === 2) {
                // 装填特殊日期
                this.$emit(
                    "change",
                    this.workingDayTableData.map((item) => {
                        let obj = {
                            date: item.working_day,
                            mode: item.punch_times,
                            type: item.is_work ? 1 : 2,
                        };
                        if (item.punch_times === 4) {
                            obj.punch1_time = item.start_time;
                            obj.punch2_time = item.sub_end_time;
                            obj.punch3_time = item.sub_start_time;
                            obj.punch4_time = item.end_time;
                        } else {
                            obj.punch1_time = item.start_time;
                            obj.punch2_time = item.end_time;
                        }
                        return obj;
                    })
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}
// 表格内的表单大小
.cell {
    .el-input {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .el-select {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .sp_date_picker {
        width: 130px !important;
    }
}

.operation_button {
    width: 142px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    margin-top: 30px;
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
