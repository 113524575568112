<template>
    <div>
        <div class="title">考勤规则设置</div>

        <el-divider></el-divider>

        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
            <el-form-item label="考勤组名称：" prop="name">
                <el-input v-model="form.name"></el-input>
            </el-form-item>

            <el-form-item label="工时制类型：" prop="work_hours_type">
                <el-radio-group v-model="form.work_hours_type">
                    <el-radio label="1">标准工时制</el-radio>
                    <el-radio label="2">综合工时制</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                label="日工时核算规则："
                prop="day_work_hours_rule_type"
            >
                <el-radio-group v-model="form.day_work_hours_rule_type">
                    <el-radio label="1">迟到、早退扣工时</el-radio>
                    <el-radio label="2">迟到、早退不扣工时</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="考勤标准时长：" prop="working_hours">
                <div class="sp_input">
                    <el-input v-model="form.working_hours"></el-input>
                    <div class="sp_input_suffix">小时/天</div>
                </div>
            </el-form-item>

            <el-form-item label="关联考勤扣款规则：">
                <el-select v-model="form.deducte_rule" placeholder="请选择">
                    <el-option
                        v-for="item in deducteRulesList"
                        :key="item.id"
                        label="item.label"
                        value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>

            <!-- 适用人员 -->
            <el-form-item label="适用部门与人员" prop="special_staff">
                <el-cascader
                    v-model="employees"
                    :options="employeesOptions"
                    :props="{ multiple: true, checkStrictly: true }"
                    collapse-tags
                ></el-cascader>
            </el-form-item>

            <!-- 出勤日表 -->
            <div class="table_title">出勤日时间</div>
            <attendance-pickers-table
                :mode="1"
                :init="work_time_init_data"
                @change="onDatesChange"
            ></attendance-pickers-table>

            <!-- 节假日 -->
            <el-form-item class="holiday">
                <el-checkbox
                    v-model="is_legal_holidays"
                    label="法定节假日休息"
                    name="holiday"
                ></el-checkbox>
            </el-form-item>

            <!-- 特殊日期 -->
            <div class="table_title">特殊日期设置</div>
            <attendance-pickers-table
                :mode="2"
                :init="punch_special_init_date"
                @change="onSpDatesChange"
            ></attendance-pickers-table>

            <!-- save -->
            <div
                class="custom_button no_select operation_button"
                @click="onSubmit"
            >
                保存
            </div>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import AttendancePickersTable from "@/components/AttendancePickersTable.vue";

export default {
    props: ["id"],
    components: {
        "attendance-pickers-table": AttendancePickersTable,
    },
    data() {
        return {
            form: {
                name: "",
                work_hours_type: "",
                day_work_hours_rule_type: "",
                working_hours: "",
                special_staff: "",
            },
            is_legal_holidays: true,

            deducteRulesList: [],
            employees: [],

            rules: {
                name: [
                    {
                        required: true,
                        message: "请填写考勤组名称",
                        trigger: "blur",
                    },
                ],
                work_hours_type: [
                    {
                        required: true,
                        message: "请选择工时制类型",
                        trigger: "blur",
                    },
                ],
                day_work_hours_rule_type: [
                    {
                        required: true,
                        message: "请选择日工时核算规则",
                        trigger: "blur",
                    },
                ],
                working_hours: [
                    {
                        required: true,
                        message: "请填写考勤标准时长",
                        trigger: "blur",
                    },
                ],
                special_staff: [
                    {
                        required: true,
                        message: "请选择使用部门与人员",
                        trigger: "blur",
                    },
                ],
            },
            employeesOptions: [],

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.getdepartmentList()
            .then((res) => {
                console.log(res);
                this.employeesOptions = res.data.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name,
                        children: item.staff.map((item) => {
                            return {
                                value: item.id,
                                label: item.real_name,
                            };
                        }),
                    };
                });
            })
            .catch((e) => {
                console.error(e);
            });

        if (this.id) {
            this.getPunchRuleDetail({ id: this.id })
                .then((res) => {
                    console.log(res);
                    res = res.data.data;
                    this.form = {
                        name: res.name,
                        work_hours_type: String(res.work_hours_type),
                        day_work_hours_rule_type: String(
                            res.day_work_hours_rule_type
                        ),
                        working_hours: res.working_hours,
                        special_staff: "",
                        id: this.id,
                    };
                    this.employees = [];
                    res.punch_special_staff.forEach((department) => {
                        if (department.staff_ids)
                            department.staff_ids
                                .split(",")
                                .map((item) => Number(item))
                                .forEach((item) => {
                                    this.employees.push([
                                        department.department_id,
                                        item,
                                    ]);
                                });
                        else {
                            this.employees.push([department.department_id]);
                        }
                    });
                    this.work_time_init_data = res.work_time_data;
                    this.punch_special_init_date = res.punch_special_date;
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    },
    methods: {
        ...mapActions("attendance", [
            "postPunchRules",
            "getdepartmentList",
            "getPunchRuleDetail",
        ]),

        onDatesChange(e) {
            console.log("接受到", e);
            this.form.work_time_data = e;
        },

        onSpDatesChange(e) {
            console.log("接受到", e);
            this.form.special_date = e;
        },

        onSubmit() {
            // 装填适用人员
            this.form.special_staff =
                this.employees.length > 0
                    ? this.employees.map((item) => {
                          return {
                              department_id: item[0],
                              staff_ids: item.slice(1).join(","),
                          };
                      })
                    : "";

            // 转换布尔格式
            this.form.is_legal_holidays = this.is_legal_holidays ? 1 : 0;

            this.$refs.form.validate((valid) => {
                if (valid) {
                    console.log(this.form);
                    let loading = this.$loading();
                    this.postPunchRules(this.form)
                        .then((res) => {
                            console.log(res);
                            loading.close();
                            this.$message.success("信息提交成功");
                            this.$router.back();
                        })
                        .catch((e) => {
                            console.log(e);
                            loading.close();
                            this.$message.error("信息提交失败");
                        });
                } else {
                    this.$notify.error({
                        title: "提交失败",
                        message: "请先完整填写表单",
                    });
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

::v-deep .el-form-item__label {
    font-size: 14px;
    color: #222222;
    width: 140px !important;
}

::v-deep .el-form-item__content {
    margin-left: 140px !important;
}
.el-radio.is-checked {
    ::v-deep .el-radio__inner {
        border-color: #4db9d5;
        background: #4db9d5;
    }

    ::v-deep .el-radio__label {
        color: #606266;
    }
}

.holiday {
    ::v-deep {
        .el-form-item__content {
            margin-left: 0 !important;
        }

        .el-checkbox__inner:hover {
            border-color: #4db9d5;
        }

        .el-checkbox__input {
            &.is-checked {
                .el-checkbox__inner {
                    background-color: #4db9d5;
                    border-color: #4db9d5;
                }
            }

            &.is-focus {
                .el-checkbox__inner {
                    border-color: #4db9d5;
                }
            }
        }

        .el-checkbox__label {
            color: #495060;
        }
    }
}

.el-input {
    width: 400px;
    height: 48px;
    font-size: 14px;
    color: #222222;
}

// 表格内的表单大小
.cell {
    .el-input {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .el-select {
        width: 100px;
        height: 40px;
        font-size: 12px;
        color: #495060;
    }

    .sp_date_picker {
        width: 130px !important;
    }
}

.sp_input {
    position: relative;
    width: max-content;
    display: inline-block;

    .sp_input_suffix {
        position: absolute;
        right: 10px;
        top: 0;
        font-size: 14px;
        color: #495060;
    }
}

.operation_button {
    width: 142px;
    height: 42px;
    line-height: 42px;
    font-size: 14px;
    margin-top: 30px;
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
